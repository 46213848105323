<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card flat class="m-0" style="background: #f7f7f7; border-radius: 8px">
      <!-- <pre>{{ workflowSelected }}</pre> -->
      <v-card-title
        class="mb-8"
        style="background: #ffffff; border-radius: 8px 8px 0px 0px"
      >
        <span class="modalTitle">Add to workflow</span>
        <v-spacer></v-spacer>
        <v-icon
          tag="button"
          @click="closeDialog"
          class="text-bolder"
          color="#596A73"
        >
          mdi-close
        </v-icon>
      </v-card-title>

      <div class="pa-md-0 ma-md-0 px-6">
        <template>
          <v-select
            @change="$emit('selectedWorkflow', { value: $event })"
            v-model="selectedWorkflow"
            background-color="#ffffff"
            :menu-props="{ bottom: true, offsetY: true }"
            :value="workflowSelected"
            :items="workflowSelected"
            item-text="workflow_title"
            class="justify-center mt-8 mx-0 mx-md-8 custom-placeholer-color"
            flat
            outlined
            placeholder="Select Workflow"
            hide-details="auto"
            return-object
          >
          </v-select>
        </template>
      </div>
      <div class="info__text px-8 py-3 mt-6 mb-4">
        By setting your inbox to a workflow, all inbox entries will
        automatically be sent to
        <span class="font-weight-bold">the workflow</span> for decision/approval
        and action
      </div>

      <template>
        <v-card-actions class="d-flex justify-end mt-6 mr-9 px-md-0">
          <v-btn
            :loading="sendingToWorkflow"
            @click="sendToWorkflow"
            dark
            color="primary"
            width="121"
            height="45"
            class="card__button"
          >
            <v-icon>mdi-chevron-right</v-icon>
            <span>Next</span>
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      selectedWorkflow: null,
      sendingToWorkflow: false,
    };
  },

  computed: {
    ...mapGetters({
      workflowSelected: "workflow/invoiceWorkflow",
    }),
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    closeDialog() {
      this.dialog = false;
      // emit the event to the parent component
      this.$emit("closeDialog", false);
    },
    openDialog(val) {
      this.dialog = val;
    },
    async sendToWorkflow() {
      this.$emit("getWorkflowTitle", this.selectedWorkflow.workflow_title);
      const payload = {
        workflow_id: this.selectedWorkflow.workflow_id,
      };

      //console.log(JSON.stringify(payload, null, 2));
      try {
        this.sendingToWorkflow = true;
        await this.$store.dispatch("invoices/sendInvoicesToWorkFlow", payload);

        this.sendingToWorkflow = false;
        this.showToast({
          message: "Invoices sent to workflow successfully",
          sclass: "success",
          show: true,
          timeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: "Error sending invoices to workflow",
          timeout: 3000,
        });
      } finally {
        this.sendingToWorkflow = false;
        this.dialog = false;
      }
    },
  },
};
</script>

<style>
input[type="checkbox" i] {
  display: none !important;
}

.modalTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #19283d;
}

.workflow__input {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  width: 330px;
  height: 54px;
}

.info__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}

.card__button {
  margin-bottom: 36px;
  background: var(--v-primary-base);
  box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
}
</style>
