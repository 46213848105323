<template>
  <div class="pa-0 ma-0">
    <v-menu left offset-y :close-on-content-click="closeMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
          color="primary"
          class="px-md-8 px-4"
          :height="btnHeight[$vuetify.breakpoint.name]"
          style="background: var(--v-primary-base); border-radius: 4px"
        >
          <img
            :src="btnIcon"
            alt="button svg icon"
            style="padding-right: 8px"
            :style="{
              width: `${$vuetify.breakpoint.mdAndUp ? '24px' : '17px'}`,
              height: `${$vuetify.breakpoint.mdAndUp ? '18px' : '12px'}`,
            }"
          />
          <span class="text-capitalize btn__text">
            {{ btnText }}
          </span>
        </v-btn>
      </template>
      <v-card class="parent-card py-6">
        <slot name="titleButton" />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    icon: String,
    btnText: String,
    closeMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // btnWidth: {
      //   xl: "132px",
      //   xs: "80px",
      //   lg: "121px",
      //   md: "121px",
      //   sm: "100px",
      // },
      btnHeight: {
        xs: "36px",
        md: "54px",
        lg: "54px",
        sm: "44px",
      },
    };
  },
  methods: {
    //
  },
  computed: {
    btnIcon() {
      return require(`@/assets/pbot_icons/${this.icon}.svg`);
    },
  },

  // mounted() {
  //   console.log(this.btnWidth[this.$vuetify.breakpoint.name]);
  //   console.log(this.$vuetify.breakpoint.name);
  // },
};
</script>

<style scoped>
.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  box-shadow: none;
  border-radius: none;
}

.btn__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.636364px;
}

.parent-card {
  width: 227px;
  background: #ffffff;
}

.list__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #7f919b;
}

.list__subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #7f919b;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.list__icon {
  mix-blend-mode: normal;
  opacity: 0.5;
}

@media only screen and (min-width: 600px) {
  .btn__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.636364px;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .btn__text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.636364px;
  }
}
</style>
